import React, {useEffect} from "react";
import { useCallback, useState, useMemo } from "react";
import TagListCommandBar from "./TagListCommandBar";
import EditTagsPanel,{ ITagInfo} from "./EditTagsPanel";
import { getTypeList } from "./../../../api";


import { Stack, Label, Checkbox, TextField, Toggle, StackItem, Dropdown, IDropdownOption } from "office-ui-fabric-react";
import Taglist, {ITagData, } from "./TagList"

export interface IQuery 
{
    from?: string;
    to?: string;
    location?: string;
    hoist?: string;
}

export interface ITagType 
{
    id: string;
    typeName: string;
}


const Tag = () =>
{
  const [tagPanelVisible, setTagPanelVisible] = useState(false);
  const [tagInfoData, setTagInfoData] = useState<ITagInfo>({editMode:false});
  const [tagType, setTagType] = useState<IDropdownOption[]>([]);
  const [selectedType, setSelectedType] = useState<number>(0);
  const [tageTypeId, settagTypeId] = useState<string | undefined>();
  const [reload, setReload] = useState<boolean>(false);

  const onAddTagClick = (item?:ITagData) => {
    
  if (item !== undefined){
    setTagInfoData({editMode:true});
  }
  else {
    setTagInfoData({editMode:false});
  }
  setTagPanelVisible(true);
}

  const onChangeGroup = (id:string |undefined) => {

    if (id !== undefined){
      setSelectedType(parseInt(id));
      const idTagType:IDropdownOption | undefined = tagType.find(x => x.key === parseInt(id));
      settagTypeId(idTagType?.data.id)
    }

  }

  const createTypeOption = (tagTypeList:ITagType[]) => {
    let tagTypeArr: IDropdownOption[] = [];
    tagTypeArr.push({ key: 0, text: 'All', data : {id:""}});
    tagTypeList.map((item,index) => {
      tagTypeArr.push({ key: index+1, text: item.typeName, data : {id: item.id}});
    })
    setTagType(tagTypeArr);
  }

  const getTypeDataList = async () => {
    const res = await getTypeList();

    if(res.ok)
    {
      const retJson:ITagType[] = await res.json();
      createTypeOption(retJson);
    }
    else
    {
    }
  }

    // training data list  
    useEffect(() => {
      const callback = async () => {
        getTypeDataList();
    }
      callback();
    }, []);


const onSave = () =>{

  setReload(!reload);

} 


  return (
    <React.Fragment>
        <TagListCommandBar onAddTagClick={() => onAddTagClick()}/>

        <div style={{marginLeft:"30px"}}>
            <div><Label>Groups</Label></div><div style={{width: "200px" }}></div>
            <div><Dropdown label="" placeholder="Select a group" style={{width: "200px" }}  onChange={(_, option) => onChangeGroup(option?.key.toString())} options={tagType} selectedKey={selectedType}>
            </Dropdown>
            </div>
        </div>

        <Taglist onTagclick={(item) => onAddTagClick(item)} tagTypeId={tageTypeId} reload={reload}></Taglist>

      <EditTagsPanel visible={tagPanelVisible} tagInfo={tagInfoData} onDismiss={() => setTagPanelVisible(false)} onSave={() => onSave()}></EditTagsPanel>


    </React.Fragment>
  )

}

export default Tag;
